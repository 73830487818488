import IRoutes from "../interfaces/IRoutes";
import GeneratedDigsig from "../pages/generatedDigsig/GeneratedDigsig";
import GeneratedDigsigField from "../pages/generatedDigsig/GeneratedDigsigField";
import GeneratedDigsigUpload from "../pages/generatedDigsig/GeneratedDigsigUpload";

const generatedDigsigRoute: Array<IRoutes> = [
    {
        element: <GeneratedDigsig />,
        path: "/digsig-data",
    },
    {
        element: <GeneratedDigsigField />,
        path: "/digsig-data/create",
    },
    {
        element: <GeneratedDigsigField />,
        path: "/digsig-data/edit/:id",
    },
    {
        element: <GeneratedDigsigField />,
        path: "/digsig-data/detail/:id",
    },
    {
        element: <GeneratedDigsigUpload />,
        path: "/digsig-data/upload/",
    }
];

export { generatedDigsigRoute };

import { Box, Pagination, Typography, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import InputForm from "../forms/inputForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import SelectForm from "../forms/selectFormm";
import formatNumberWithCommas from "../../../utils/hash/dotSparator";
// import { useGetResultBlobMutation } from "../../../store/feature/service/logResultApiSlice";
import { RootState, useAppSelector } from "../../../store";
import useWindowDimensions from '../../getRes';

const PaginationControl = ({ params, setParams, total }: any) => {
  // const [page, setPage] = useState(1);
  const savedPagesize = localStorage.getItem("pagesize");
  const savedPage = localStorage.getItem("page");
  const [page, setPage] = useState<number>(() => {
    // Load page from localStorage or default to 1
    return savedPage ? parseInt(savedPage, 10) : 1;
  });
  const [pagesize, setPagesize] = useState<number>(() => {
    // Load pagesize from localStorage or default to 10
    return savedPagesize ? parseInt(savedPagesize, 10) : 10;
  });
  // const { keyPage } = useAppSelector((store: RootState) => store.logResult);
  const { height, width } = useWindowDimensions();
  const schema = yup.object({
    goTo: yup.number().required(),
  });
  const {
    control,
    formState: { errors },
    setValue
  } = useForm<{
    goTo: number;
    perPage: any;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      goTo: 0,
      perPage: ""
    },
  });

  const handlePerPage = (e: any) => {
    setPagesize(e.value);

    localStorage.setItem("pagesize", e.value.toString());
  };

  const handlePaginate = (page: any) => {
    setPage(page);
    // Save the selected page to localStorage
    localStorage.setItem("page", page.toString());
  };


  useEffect(() => {
    let tempPage = page;
    const totalPage = calculateTotalPage();
    if (tempPage > totalPage) {
      tempPage = totalPage;
      setPage(tempPage);
      localStorage.setItem("page", tempPage.toString());
    }
    setParams({
      ...params,
      page: tempPage,
      pagesize,
    });
    if(savedPagesize){
      setValue('perPage', Number(savedPagesize))
    }
  }, [page, pagesize]);

  // useEffect(()=> {
  //   setPage(1)
  // }, [keyPage])

  const calculateTotalPage = () => {
    return Math.ceil(total / pagesize);
  };

  const handlePageGoto = (e: any) => {
    if (e === "" || e === undefined) {
      setPage(1);
      return;
    }
    setPage(e);
  };
  const optSelect = [
    {
      label: "10/page",
      value: 10,
    },
    {
      label: "20/page",
      value: 20,
    },
    {
      label: "30/page",
      value: 30,
    },
    {
      label: "50/page",
      value: 50,
    },
    {
      label: "100/page",
      value: 100,
    },
  ];

  return (
    // <Box
    //   sx={{
    //     flexDirection: "row",
    //     display: "flex",
    //     justifyContent: "flex-start",
    //     alignItems: "center",
    //     paddingRight: "20px",
    //     gap: 4,
    //   }}
    // >
      <Grid container 
        justifyContent="center"
        alignItems="center"
        direction={width < 550 ? "column" : "row"}
        spacing={width <550 ? 1 : 3}
      >
      <Grid item xs={12} sm={12} md={4}>
      <Box
        sx={{
          mt: "9px",
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          width: "170px",
        }}
      >
        <Grid container 
          justifyContent="center"
          alignItems="center"
        >
        <Grid item xs={5} sm={5} md={5} sx={{mb: 1}}>
        <Typography
          variant="paragraph1"
          fontFamily="Open Sans"
          paddingBottom={"15px"}
        >
          Total {formatNumberWithCommas(total || 0 )}
        </Typography>
        </Grid>
        <Grid item xs={7} sm={7} md={7}>
        <SelectForm
          name="perPage"
          defaultValue={1}
          options={optSelect}
          placeholder="10/page"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          onChangeForm={handlePerPage}
        />
        </Grid>
        </Grid>
      </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
      <Pagination
        count={calculateTotalPage()}
        variant="outlined"
        shape="rounded"
        onChange={(event, page) => handlePaginate(page)}
        page={page}
      />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
      <Box
        sx={{
          mt: "9px",
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="body1"
          fontFamily="Open Sans"
          paddingBottom={"15px"}
        >
          Go to
        </Typography>
        <InputForm
          onChange={handlePageGoto}
          name="goTo"
          required
          maxLength={3}
          control={control}
          disabled={false}
          errors={errors}
          isLoading={false}
          sxFL={{ color: "red", backgroundColor: "red" }}
          endAdornment={<Typography variant="body16Regular"></Typography>}
          sxEndAdornment={{ backgroundColor: "red" }}
        />
      </Box>
      </Grid>
      </Grid>
    // </Box>
  );
};

export default PaginationControl;

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { useGetDddNameListQuery } from "../../store/feature/service/dddFields";
import { useGetDddParamDefUidMutation } from "../../store/feature/service/dddParamDefApiSlice";

import generateDigsig from "../../utils/validation/generateDigsig";
import {
  useCreateEntryAndValMutation,
  useCreateGenDigMutation,
  useGetGeneratedDigsigDetailMutation,
  usePostGenDigsigMutation,
  useUpdateEntryAndValMutation,
} from "../../store/feature/service/generateDigsigApiSlice";
import HelpIcon from "@mui/icons-material/Help";
import ModalInfo from "./ModalInfo";
import { useGetParamDefByUIDMutation } from "../../store/feature/service/paramDef";
import GenDigsigUpdtCreateTable from "../../components/molecules/generatedDigsig/GenDigsigUpdtCreateTable";
import generateDigsigUpdateCreate from "../../utils/validation/generateDigsigUpdateCreate";

const GeneratedDigsigField = () => {
  // REGION: INIT VARIABLES
  const location = useLocation();
  const navigate = useNavigate();
  const { id: idParam } = useParams();
  const { pathname } = location;
  const detailFgroup = pathname.includes("detail");
  const editFgroup = pathname.includes("edit");
  const [optDDDName, setOptDDDName] = useState([]);
  const [dataParamDefTable, setDataParamDefTable] = useState([]);
  const [cert, setCert] = useState("");
  const [dDDFieldUid, setDDDFieldUid] = useState("");
  const [dataParam, setDataParam] = useState([]);
  const [mergedArr, setMergedArr] = useState([]);
  const [open, setOpen] = useState(false);

  const { data, isError, isSuccess } = useGetDddNameListQuery({});
  const [
    GetDddParamDefUid,
    { data: dataParamDef, isError: errorParamDef, isSuccess: successParamDef },
  ] = useGetDddParamDefUidMutation();

  // new for page
  const [
    GetGeneratedDigsigDetail,
    {
      data: dataDigsigDetail,
      isError: errorDigsigDetail,
      isSuccess: successDigsigDetail,
      isLoading: loadingDigsigDetail,
    },
  ] = useGetGeneratedDigsigDetailMutation();
  // useCreateEntryAndValMutation
  const [
    CreateEntryAndVal,
    {
      data: dataCreateEntryVal,
      isError: errorCreateEntryVal,
      isSuccess: successCreateEntryVal,
      isLoading: loadingCreateEntryVal,
    },
  ] = useCreateEntryAndValMutation();
  // usePostGenDigsigMutation
  const [
    PostGenDigsig,
    {
      data: dataPostEntryVal,
      isError: errorPostEntryVal,
      isSuccess: successPostEntryVal,
      isLoading: loadingPostEntryVal,
    },
  ] = usePostGenDigsigMutation();
  const [
    UpdateEntryAndVal,
    {
      data: dataUpdateEntryVal,
      isError: errorUpdateEntryVal,
      isSuccess: successUpdateEntryVal,
      isLoading: loadingUpdateEntryVal,
    },
  ] = useUpdateEntryAndValMutation();

  const [
    GetParamDefByUID,
    {
      data: dataParamDefByUID,
      isError: errorParamDefByUID,
      isSuccess: successParamDefByUID,
    },
  ] = useGetParamDefByUIDMutation();
  // will be delete
  const [
    CreateGenDig,
    {
      data: dataCreateGen,
      isError: errorCreateGen,
      isSuccess: successCreateGen,
      isLoading: loadingCreateGen,
    },
  ] = useCreateGenDigMutation();
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    compName: string;
    dddField: string;
    entryName: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(generateDigsigUpdateCreate),
    defaultValues: {
      compName: "",
      dddField: "",
      entryName: "",
    },
  });

  // REGION: CHANGE STATE
  useEffect(() => {
    if (data) {
      setOptDDDName(
        data?.data.map((item: any) => ({
          label: item.dsg_ddd_name,
          value: item.dsg_ddd_uid,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (dataDigsigDetail) {
      console.log("dataDigsigDetail => ", dataDigsigDetail?.data[0]);
      setValue("compName", dataDigsigDetail?.data[0].dsg_ddd_entry_company);
      setValue("dddField", dataDigsigDetail?.data[0].dsg_ddd_uid);
      setValue("entryName", dataDigsigDetail?.data[0].dsg_ddd_entry_name);
      setDataParamDefTable(dataDigsigDetail);
      GetParamDefByUID(dataDigsigDetail?.data[0].dsg_ddd_uid);
    }
  }, [dataDigsigDetail]);

  const mergedArray = () => {
    return dataDigsigDetail?.data.map((item1: any) => {
      let fieldSpecArr = dataParamDefByUID?.data
        .filter(
          (item2: any) =>
            item2.dsg_ddd_field_param_definition_uid ===
            item1.dsg_ddd_field_param_definition_uid
        )
        .map((item2: any) => ({
          dsg_ddd_param_fieldspec_name: item2.dsg_ddd_param_fieldspec_name,
          dsg_ddd_param_fieldspec_definition:
            item2.dsg_ddd_param_fieldspec_definition,
        }));
      return {
        ...item1,
        field_spec_arr: fieldSpecArr,
      };
    });
  };
  const transformData = (arr: any) => {
    const grouped = arr.reduce((acc: any, current: any) => {
      const label = current.dsg_ddd_field_param_definition_label;
      const definitionUid = current.dsg_ddd_field_param_definition_uid;

      if (!acc[label]) {
        acc[label] = {
          dsg_ddd_field_param_definition_label: label,
          dsg_ddd_field_param_definition_uid: definitionUid,
          field_spec_arr: [],
        };
      }

      acc[label].field_spec_arr.push({
        dsg_ddd_param_fieldspec_name: current.dsg_ddd_param_fieldspec_name,
        dsg_ddd_param_fieldspec_definition:
          current.dsg_ddd_param_fieldspec_definition,
      });

      return acc;
    }, {});

    return Object.values(grouped);
  };
  useEffect(() => {
    if (dataParamDefByUID) {
      if (detailFgroup || editFgroup) {
        setMergedArr({ data: mergedArray() } as any);
      } else {
        setMergedArr({ data: transformData(dataParamDefByUID?.data) } as any);
      }
    }
  }, [dataParamDefByUID]);

  useEffect(() => {
    if (detailFgroup || editFgroup) {
      GetGeneratedDigsigDetail(idParam);
    }
  }, [detailFgroup]);

  const combineArrays = (arr1: any, arr2: any, compName: string) => {
    // Create a map for quick lookup of paramUid to fieldParamFor and inputValType
    const paramMap = arr1.reduce((map: any, item: any) => {
      map[item.fieldParamFor] = { uid: item.paramUid, type: item.inputValType };
      return map;
    }, {});

    // Process arr2 to create the desired output
    return arr2
      .map((entry: any) => {
        // Get ddd_values based on arr1 mapping
        const ddd_values = Object.keys(paramMap).map((field) => {
          const { uid, type } = paramMap[field];

          let typeMode;

          if (type === "string") {
            typeMode = entry[field];
          } else if (type === "number") {
            typeMode = Number(entry[field]);
          } else if (type === "null") {
            typeMode = null;
          } else if (type === "array") {
            typeMode = [entry[field]];
          } else if (type === "json") {
            typeMode = JSON.parse([entry[field]] as any);
          }

          return {
            dsg_ddd_field_param_definition_uid: uid,
            dsg_ddd_value_data: typeMode,
            dsg_ddd_value_type: type,
          };
        });

        // Return the formatted object
        return {
          ddd_values,
          dsg_ddd_entry_name: entry["entry name"] || "",
          dsg_ddd_entry_company: compName,
          dsg_ddd_uid: dDDFieldUid,
        };
      })
      .filter((item: any) =>
        item.ddd_values.some((value: any) => value.dsg_ddd_value_data)
      ); // Filter out empty entries
  };

  useEffect(() => {
    if (dataCreateEntryVal) {
      const id = dataCreateEntryVal?.data?.dsg_ddd_entry_uid;
      PostGenDigsig(id);
    } else if (dataUpdateEntryVal) {
      // const id = dataCreateEntryVal?.data?.dsg_ddd_entry_uid;
      PostGenDigsig(idParam);
    }
  }, [dataCreateEntryVal, dataUpdateEntryVal]);

  const onSubmit = (e: any) => {
    if (!editFgroup) {
      if (dataParam.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Value Type",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#051438",
          text: "Value Type Must be Filled",
        });
        return;
      }
    }
    console.log("data param ===> ", dataParam);

    // const combinedArray = combineArrays(dataParam, cert, e.compName);
    // console.log("combineArray ===> ", combinedArray);
    let mapData = dataParam?.map((item: any) => {
      let typeMode;

      if (item.inputValType === "string") {
        typeMode = item.enterVal;
      } else if (item.inputValType === "number") {
        typeMode = Number(item.enterVal);
      } else if (item.inputValType === "null") {
        typeMode = null;
      } else if (item.inputValType === "array") {
        typeMode = [item.enterVal];
      } else if (item.inputValType === "json") {
        typeMode = JSON.parse(item.enterVal as any);
      }

      return !editFgroup
        ? {
            dsg_ddd_field_param_definition_uid: item.paramUid,
            dsg_ddd_value_data: typeMode,
            dsg_ddd_value_type: item.inputValType,
          }
        : {
            dsg_ddd_value_uid: item.valUid,
            dsg_ddd_value_data: typeMode,
            dsg_ddd_value_type: item.inputValType,
          };
    });
    let tempObj: any = {
      ddd_values: mapData,
      dsg_ddd_entry_company: e.compName,
      dsg_ddd_entry_name: e.entryName,
      dsg_ddd_uid: e.dddField,
    };

    Swal.fire({
      title: "Submit Confirmation",
      text: "Are you sure you want to submit the ddd value data?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (!editFgroup) {
          CreateEntryAndVal(tempObj);
        } else {
          UpdateEntryAndVal({ id: idParam, body: mapData });
        }
      }
    });
  };
  const handleChangeDDDName = (e: any) => {
    setDDDFieldUid(e.value);
    GetParamDefByUID(e.value);
  };

  const showFirstAlert = () => {
    Swal.fire({
      title: "Confirm revoke key",
      html: `
        <p style="margin-bottom: 10px; text-align: left;">
          You're about to permanently mark this keypair as invalid and untrustworthy before its intended expiration time.
        </p>
        <p style="margin-bottom: 10px; text-align: left;">
          <strong>Are you sure you want to revoke this key? <br/> This action cannot be undone.</strong> 
        </p>
        <p style="text-align: left;">
          If you're not sure, you can select cancel instead.
        </p>
      `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      iconHtml: renderToStaticMarkup(
        <CancelIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, revoke key!",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("Key revoked");
      } else {
        Swal.fire({
          title: "Your changes won’t be saved",
          html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#808080",
          iconHtml: renderToStaticMarkup(
            <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
          ),
          confirmButtonText: "Discard Data",
          cancelButtonText: "Go back",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            console.log("Data discarded");
          } else {
            showFirstAlert(); // Call the first alert again
          }
        });
      }
    });
  };

  useEffect(() => {
    if (successCreateGen) {
      Swal.fire({
        icon: "success",
        title: "Successfully Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Successfully Upload Digsig Value",
      }).then(() => navigate("/digsig-data"));
    }
  }, [successCreateGen]);

  useEffect(() => {
    if (errorCreateGen) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Digsig Certificate",
      });
    }
  }, [errorCreateGen]);

  // useEffect(() => {
  //   if (successCreateEntryVal) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Successfully Create",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Successfully Create Digsig Value",
  //     }).then(() => navigate("/digsig-data"));
  //   }
  // }, [successCreateEntryVal]);

  useEffect(() => {
    if (errorCreateEntryVal) {
      Swal.fire({
        icon: "error",
        title: "Error Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "There's an error in your input value for DDD value data.",
      });
    }
  }, [errorCreateEntryVal]);

  // useEffect(() => {
  //   if (successUpdateEntryVal) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Successfully Update",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Successfully Update Digsig Value",
  //     }).then(() => navigate("/digsig-data"));
  //   }
  // }, [successUpdateEntryVal]);

  useEffect(() => {
    if (errorUpdateEntryVal) {
      Swal.fire({
        icon: "error",
        title: "Error Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Digsig Certificate",
      });
    }
  }, [errorUpdateEntryVal]);

  useEffect(() => {
    if (successPostEntryVal) {
      Swal.fire({
        icon: "success",
        title: "Successfully Generated",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Successfully Generated",
      }).then(() => navigate("/digsig-data"));
    }
  }, [successPostEntryVal]);

  useEffect(() => {
    if (errorPostEntryVal) {
      Swal.fire({
        icon: "error",
        title: "Error Generated",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "There's and error when generating digsig data, please make sure the DDD value is match with the DDD fields format",
      });
    }
  }, [errorPostEntryVal]);

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    navigate("/digsig-data");
    // showFirstAlert();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleInfo = () => {
    setOpen(true);
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={loadingDigsigDetail}
      isLoadingEvent={loadingCreateGen}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Grid container spacing={2}>
          <Grid item lg={10}>
            <Typography variant="h3" fontFamily="Open Sans" mb={3}>
              {editFgroup
                ? "Update Digsig Value"
                : detailFgroup
                ? "Detail Digsig Value"
                : "Create Digsig Value"}
            </Typography>
          </Grid>
          <Grid item lg={2} display={"flex"} justifyContent={"flex-end"}>
            <IconButton
              // aria-describedby={id}
              onClick={handleInfo}
              style={{ padding: 0, color: "black", paddingLeft: "7px" }}
            >
              <HelpIcon sx={{ height: "50px", width: "50px" }} />
            </IconButton>
            <ModalInfo open={open} handleClose={handleClose} />
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="compName"
              label="DIGSIG Company Name/ID"
              placeholder="Type DIGSIG Company Name/ID"
              disabled={detailFgroup || editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </Grid>
          <Grid item lg={12}>
            {/* {detailFgroup ? (
              <InputForm
                name="dddField"
                label="DDD Field"
                placeholder="Type Entry Name"
                disabled={detailFgroup}
                isLoading={false}
                control={control}
                errors={errors}
                maxLength={500}
                required
              />
            ) : ( */}
            <SelectFormm
              name="dddField"
              label="DDD Field"
              defaultValue={0}
              options={optDDDName}
              placeholder="none"
              disabled={detailFgroup || editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              onChangeForm={handleChangeDDDName}
              required
            />
            {/* )} */}
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="entryName"
              label="Entry Name"
              placeholder="Type Entry Name"
              disabled={detailFgroup || editFgroup}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={2} sx={{ mb: "10px" }}>
              <Grid item lg={12}>
                <GenDigsigUpdtCreateTable
                  detail={detailFgroup}
                  data={mergedArr}
                  setData={setMergedArr}
                  dataParam={dataParam}
                  setDataParam={setDataParam}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              mb={"20px"}
            >
              <Button
                variant="contained"
                type="button"
                color="gray"
                onClick={handleRevoke}
              >
                {detailFgroup ? "Back" : "Cancel"}
              </Button>
              {!detailFgroup && (
                <Button variant="contained" type="submit">
                  Submit & Generate
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </DetailDataSkeleton>
  );
};

export default GeneratedDigsigField;

import { Box, Button, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import {
  useDeleteImportKeyMutation,
  useEksportKeyDsaMutation,
  useEksportKeyRsaMutation,
} from "../../../store/feature/service/importKeyApiSlice";
import { useEffect, useState } from "react";
import {
  useDeleteDddFieldsMutation,
  useGenerateKeyDddFieldsMutation,
} from "../../../store/feature/service/dddFields";
const DddFieldsTable = ({
  data,
  params,
  setParams,
  generateKeyField,
  successGenerateKey,
  isErrorGenerateKey
}: any) => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const [pemName, setPemName] = useState("");
  const [deleteDddField, { isSuccess: successDelete, isError: errorDelete }] =
    useDeleteDddFieldsMutation();
  // const [
  //   generateKeyField,
  //   {
  //     isSuccess: successGenerateKey,
  //     isError: isErrorGenerateKey,
  //     isLoading: loadingGenerateKey,
  //     data: dataGenerateKey,
  //     error: errorGenerateKey,
  //   },
  // ] = useGenerateKeyDddFieldsMutation();

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onUpdate = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/ddd-fields/edit/${params.row.uid}`);
    };

    const onCopyID = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/ddd-fields/detail/${params.row.uid}`);
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this DDD Field? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteDddField(params.row.uid);
        }
        console.log("kid ===> ", {
          kid: params.row.kid,
        });
      });
    };

    const onGenerateKey = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Generate Confirmation",
        text: "Are you sure generate this Key DDD Field ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          generateKeyField(params.row.uid);
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onUpdate}>
            Update
          </Button>
          <Button variant="contained" onClick={onCopyID} color="success">
            Detail
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
          <Button variant="contained" onClick={onGenerateKey} color="warning">
            Generate Key
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "specificationVer",
      headerName: "Spesification Version",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "dauri",
      headerName: "Dauri",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 400,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  // REGION: INIT ROWS
  console.log("data form table ===> ", data);
  const dataRow =
    data?.data !== undefined && data?.data !== null ? data?.data : [];
  const rows = dataRow?.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    name: row.dsg_ddd_name,
    specificationVer: row.dsg_ddd_spec_version,
    createdAt: row.dsg_ddd_created_at,
    dauri: row.dsg_ddd_dauri,
    uid: row.dsg_ddd_uid,
    // appName: row.sworldAppname,
    // curved: row.curve
  }));

  useEffect(() => {
    if (successGenerateKey) {
      Swal.fire({
        icon: "success",
        title: "Generate Key Success",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "key successfully generated, go to keys management menu to download the CSR",
      });
    }
  }, [successGenerateKey]);

  useEffect(() => {
    if (isErrorGenerateKey) {
      Swal.fire({
        icon: "error",
        title: "Error Generate Key",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Generate Key",
      });
    }
  }, [isErrorGenerateKey]);

  useEffect(() => {
    if (successDelete) {
      Swal.fire({
        icon: "success",
        title: "Delete Key Success",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "success deleting DDD data",
      });
    }
  }, [successDelete]);

  useEffect(() => {
    if (errorDelete) {
      Swal.fire({
        icon: "error",
        title: "Error Delete Key",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "error deleting DDD data",
      });
    }
  }, [errorDelete]);

  // useEffect(() => {
  //   console.log("hahaha rsa ==> ", dataRsa);
  //   if (dataRsa) {
  //     const url = window.URL.createObjectURL(new Blob([dataRsa.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", pemName + ".pem");
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // }, [dataRsa]);

  // useEffect(() => {
  //   if (dataDsa) {
  //     const url = window.URL.createObjectURL(new Blob([dataDsa.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", pemName + ".pem");
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  //   console.log("hahaha dsa ==> ");
  // }, [dataDsa]);

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.total_records}
      />
    </>
  );
};

export default DddFieldsTable;

import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useDeleteGenDigsigErrLogMutation } from "../../../store/feature/service/generateDigsigApiSlice";
import CloseIcon from "@mui/icons-material/Close";

const GeneratedErrorLogTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [open, setOpen] = useState(false);
  const [messageErr, setMessageErr] = useState("")
  const [DeleteGenDigsigErrLog, { data: dataDeleteErr, isError, isSuccess }] =
    useDeleteGenDigsigErrLogMutation();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  // REGION: HANDLE DELETE SELECTED ROWS
  const handleDeleteSelected = () => {
    if (selectionModel.length === 0) {
      Swal.fire(
        "No selection",
        "Please select at least one entry to delete.",
        "warning"
      );
      return;
    }

    Swal.fire({
      title: "Delete Confirmation",
      text: "Are you sure you want to delete the selected configurations? You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        let resultArr: any = [];
        selectionModel.forEach((id) => {
          const row = rows.find((row: any) => row.id === id);
          if (row) {
            const data = {
              // uc_ocsp_config_status: "Deleted",
              dsg_generated_error_info_uid: row.errorUid,
            };
            resultArr.push(data);
            // dispatch(deleteOcspConfiguration(data));
          }
        });
        console.log("result arr ===> ", resultArr);
        DeleteGenDigsigErrLog(resultArr);
      }
    });
  };

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      console.log('mantap suratap gan ==> ', params.row )
      setMessageErr(params.row.error_message)
      setOpen(true)
    };

    return (
      <Stack spacing={2} direction="row">
        <Button variant="contained" onClick={onEdit}>
          Error Message
        </Button>
      </Stack>
    );
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ModalAction = ({messageError}: any) => {
    console.log('masak gak keluar ===> ', messageError)
    return (
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: "30%", padding: 5 }}>
          <IconButton
            sx={{ position: "absolute", top: 12, right: 12 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h2"
            id="child-modal-title"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            {messageError}
          </Typography>
        </Box>
      </Modal>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "entryName",
      headerName: "Entry Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "createAt",
      headerName: "Create At",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  // REGION: INIT ROWS
  const dataRow = data?.data !== undefined ? data?.data?.data : [];
  const totalConfig = data?.data !== undefined ? data?.data?.total_records : 0;
  const rows = dataRow?.map((row: any, index: any) => ({
    id: index,
    errorUid: row.digsig_error_uid,
    entryName: row.entry_name,
    createAt: row.dsg_generated_error_info_created_at,
    error_message: row.error_message
  }));

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Digsig Certificate",
      });
      // Clear selection model after successful deletion
      setSelectionModel([]);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Digsig Certificate",
      });
    }
  }, [isError]);

  // REGION: RETURN TSX
  return (
    <>
      <Stack direction="row" spacing={2} my={2}>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteSelected}
        >
          Delete Selected
        </Button>
      </Stack>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          checkboxSelection
          onSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
          selectionModel={selectionModel}
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalConfig}
      />
      <ModalAction messageError={messageErr}/>
    </>
  );
};

export default GeneratedErrorLogTable;

import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { IOpenMainProps } from "../../../interfaces/IUtils";

// Icons
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { clearStorage } from "../../../utils/storage";
import { useAppDispatch } from "../../../store";
import { removeToken } from "../../../store/feature/reducers/userReducer";
import { useLogoutMutation } from "../../../store/feature/service/dashboard";

const drawerWidth = 320;
const Navbar = ({ open, setOpen }: IOpenMainProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const settings = ["Change Password", "Logout"];
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [offset, setOffset] = useState(0);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { pathname } = useLocation();
  const param = useParams();
  const [
    UserLogout,
    {
      data: logoutPayload,
      isLoading: logoutLoading,
      isSuccess: logoutSuccess,
      isError: logoutError,
    },
  ] = useLogoutMutation();
  const breadcrumb: any = [{ label: "Dashboard", href: "/" }];

  // REGION: BREADCRUMB NON DYMANIC
  if (pathname === "/key") {
    breadcrumb.push({ label: "Key", href: "/key" });
  } else if (pathname === "/key/create") {
    breadcrumb.push({ label: "Key", href: "/key" });
    breadcrumb.push({ label: "Create Key ", href: "/key/create" });
  } else if (pathname === `/key/detail`) {
    breadcrumb.push({ label: "Key", href: "/key" });
    breadcrumb.push({ label: "Detail Key ", href: `/key/detail` });
  }

  // profile
  else if (pathname === "/my-profile") {
    breadcrumb.push({ label: "My Profile", href: "/my-profile" });
  }

  if (pathname === "/import-key") {
    breadcrumb.push({ label: "List Import Key", href: "/import-key" });
  } else if (pathname === "/import-key/create") {
    breadcrumb.push({ label: "List Import Key", href: "/import-key" });
    breadcrumb.push({ label: "List Import Key ", href: "/import-key/create" });
  } else if (pathname === "/import") {
    breadcrumb.push({ label: "List Import Key ", href: "/import-key" });
    breadcrumb.push({ label: "Import Key", href: "/import" });
  }

  if (pathname === "/key-management") {
    breadcrumb.push({ label: "Key Management", href: "/key-management" });
  } else if (pathname === "/key-management/create") {
    breadcrumb.push({ label: "Key Management", href: "/key-management" });
    breadcrumb.push({ label: "Create ", href: "/key-management/create" });
  } else if (pathname === `/key-management/edit/${param.id}`) {
    breadcrumb.push({ label: "Key Management", href: "/key-management" });
    breadcrumb.push({
      label: "Edit",
      href: `/key-management/edit/${param.id}`,
    });
  } else if (pathname === `/key-management/detail/${param.id}`) {
    breadcrumb.push({ label: "Key Management", href: "/key-management" });
    breadcrumb.push({
      label: "Detail",
      href: `/key-management/detail/${param.id}`,
    });
  }

  // digsig-certficate/list-certificates
  if (pathname === "/digsig-certificate") {
    breadcrumb.push({
      label: "Digsig Certificate",
      href: "/digsig-certificate",
    });
  } else if (pathname === "/digsig-certificate/create") {
    breadcrumb.push({
      label: "Digsig Certificate",
      href: "/digsig-certificate",
    });
    breadcrumb.push({ label: "Create ", href: "/digsig-certificate/create" });
  } else if (pathname === `/digsig-certificate/edit/${param.id}`) {
    breadcrumb.push({
      label: "Digsig Certificate",
      href: "/digsig-certificate",
    });
    breadcrumb.push({
      label: "Edit",
      href: `/digsig-certificate/edit/${param.id}`,
    });
  } else if (pathname === `/digsig-certificate/detail/${param.id}`) {
    breadcrumb.push({
      label: "Digsig Certificate",
      href: "/digsig-certificate",
    });
    breadcrumb.push({
      label: "Detail",
      href: `/digsig-certificate/detail/${param.id}`,
    });
  }

  // generated-error-log
  if (pathname === "/generated-error-log") {
    breadcrumb.push({
      label: "Generated Error Log",
      href: "/generated-error-log",
    });
  }

  // domain-authority-certificate
  if (pathname === "/domain-authority-certificate") {
    breadcrumb.push({
      label: "Domain Authority Certificate",
      href: "/domain-authority-certificate",
    });
  } else if (pathname === "/domain-authority-certificate/create") {
    breadcrumb.push({
      label: "Domain Authority Certificate",
      href: "/domain-authority-certificate",
    });
    breadcrumb.push({
      label: "Create ",
      href: "/domain-authority-certificate/create",
    });
  } else if (pathname === `/domain-authority-certificate/edit/${param.id}`) {
    breadcrumb.push({
      label: "Domain Authority Certificate",
      href: "/domain-authority-certificate",
    });
    breadcrumb.push({
      label: "Edit",
      href: `/domain-authority-certificate/edit/${param.id}`,
    });
  } else if (pathname === `/domain-authority-certificate/detail/${param.id}`) {
    breadcrumb.push({
      label: "Domain Authority Certificate",
      href: "/domain-authority-certificate",
    });
    breadcrumb.push({
      label: "Detail",
      href: `/domain-authority-certificate/detail/${param.id}`,
    });
  }
  // digsig-data
  if (pathname === "/digsig-data") {
    breadcrumb.push({
      label: "Generated DIGSIG",
      href: "/digsig-data",
    });
  } else if (pathname === "/digsig-data/create") {
    breadcrumb.push({
      label: "Generated DIGSIG",
      href: "/digsig-data",
    });
    breadcrumb.push({
      label: "Create ",
      href: "/digsig-data/create",
    });
  } else if (pathname === `/digsig-data/edit/${param.id}`) {
    breadcrumb.push({
      label: "Generated DIGSIG",
      href: "/digsig-data",
    });
    breadcrumb.push({
      label: "Edit",
      href: `/digsig-data/edit/${param.id}`,
    });
  } else if (pathname === `/digsig-data/detail/${param.id}`) {
    breadcrumb.push({
      label: "Generated DIGSIG",
      href: "/digsig-data",
    });
    breadcrumb.push({
      label: "Detail",
      href: `/digsig-data/detail/${param.id}`,
    });
  }else if (pathname === `/digsig-data/upload`) {
    breadcrumb.push({
      label: "Generated DIGSIG",
      href: "/digsig-data",
    });
    breadcrumb.push({
      label: "Upload",
      href: `/digsig-data/upload/${param.id}`,
    });
  } else if (pathname === "/license-server") {
    breadcrumb.push({ label: "License Server", href: "/license-server" });
  } else if (pathname === "/license-server/create") {
    breadcrumb.push({ label: "License Server", href: "/license-server" });
    breadcrumb.push({
      label: "Create License Server ",
      href: "/license-server/create",
    });
  } else if (pathname === `/license-server/license-detail/${param.id}`) {
    breadcrumb.push({ label: "License Server", href: "/license-server" });
    breadcrumb.push({
      label: "Detail License Server ",
      href: `/license-server/license-detail/${param.id}`,
    });
  } else if (pathname === `/license-server/license-detail/${param.id}/create`) {
    breadcrumb.push({ label: "License Server", href: "/license-server" });
    breadcrumb.push({
      label: "Detail License Server ",
      href: `/license-server/license-detail/${param.id}`,
    });
    breadcrumb.push({
      label: "Create License Server Detail",
      href: `/license-server/license-detail/${param.id}/create`,
    });
  }

  // New Breadscrumb
  if (pathname === "/ddd-fields") {
    breadcrumb.push({ label: "DDD Fields", href: "/ddd-fields" });
  } else if (pathname === "/ddd-fields/create") {
    breadcrumb.push({ label: "DDD Fields", href: "/ddd-fields" });
    breadcrumb.push({ label: "Create", href: "/ddd-fields/create" });
  } else if (pathname === `/ddd-fields/edit/${param.id}`) {
    breadcrumb.push({ label: "DDD Fields", href: "/ddd-fields" });
    breadcrumb.push({ label: "Edit", href: `/ddd-fields/edit/${param.id}` });
  } else if (pathname === `/ddd-fields/detail/${param.id}`) {
    breadcrumb.push({ label: "DDD Fields", href: "/ddd-fields" });
    breadcrumb.push({
      label: "Detail",
      href: `/ddd-fields/detail/${param.id}`,
    });
  }

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleButton = (setting: any) => {
    if (setting === "Logout") {
      UserLogout("");
      dispatch(removeToken());
      navigate("/login", { replace: true });
      clearStorage();
    } else if (setting === "Change Password") {
      navigate("/change-password");
    }
  };

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        display: "flex",
        background: "transparent",
        // background: 'yellow',
        boxShadow: "none",
        // backgroundColor: '#B3c100',
        alignItems: "space-between",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
    >
      <Toolbar sx={{ borderRadius: "none", paddingLeft: 0 }} variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 3.7,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          {offset <= 30 && (
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                backgroundColor: "transparent",
                flex: 1,
                marginTop: "12px",
                cursor: "pointer",
              }}
            >
              {breadcrumb.map((item: any, index: Number) => {
                return index != breadcrumb.length - 1 ? (
                  <Link
                    key={item.label}
                    onClick={() => navigate(item.href)}
                    underline="hover"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Typography color="textPrimary">{item.label}</Typography>
                );
              })}
            </Breadcrumbs>
          )}

          {/* <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
            </IconButton>
          </Tooltip> */}

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                {/* <Typography textAlign="center">{setting}</Typography> */}
                <Button
                  onClick={() => handleButton(setting)}
                  sx={{
                    width: "100%",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    paddingX: "12px",
                  }}
                >
                  {setting}
                </Button>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer */}
        {/* </Typography> */}
        {/* <Navbar /> */}
      </Toolbar>
    </MuiAppBar>
  );
};

export default Navbar;

// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const domainAuthorCertApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDACert: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/da-certificate/list`,
          params: params,
        };
      },
      providesTags: ["GetDACert"],
    }),
    getDACertByUid: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/da-certificate/detail/${params}`,
          method: "GET",
        };
      },
      providesTags: ["GetDACertByUid"],
    }),
    createDACert: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/da-certificate/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateDACert", "GetDACert"],
    }),
    updateDACert: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `/da-certificate/update-da-root-certificate/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdateDACert", "GetDACert"],
    }),
  }),
});

export const {
  useGetDACertQuery,
  useCreateDACertMutation,
  useGetDACertByUidQuery,
  useUpdateDACertMutation,
} = domainAuthorCertApiSlice;
